import React, { lazy, Suspense } from 'react';

const IntroTextNacoWebStrankyCCh2 = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'IntroTextNacoWebStrankyCCh2Lazy' */ './IntroTextNacoWebStrankyCCh2'));

function IntroTextNacoWebStrankyCCh2Lazy(props) {
    return (
        <div>
            <Suspense fallback={<div>Loading IntroTextNacoWebStrankyCCh2 ...</div>}>
                <IntroTextNacoWebStrankyCCh2 {...props} />
            </Suspense>
        </div>
    );
}

export default IntroTextNacoWebStrankyCCh2Lazy;